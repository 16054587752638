<template>
    <div>
                <!-- Platforms Section -->
                <!-- Home Section -->
                <section class="home-section bg-dark-alfa-70 parallax-1 pb-120 pt-120" id="home" style="background-image: url('img/vr-1-bw.jpg');">
                    <div class="container d-flex align-items-top mb-80" style="padding:0px;">
                        <div class="container relative">
                            <div class="text-center mt-20 mb-20 mb-sm-50">
                                <h2 class="section-title">Take Your Game to the Metaverse</h2>
                                <p class="section-title-descr"  style="text-align:left;">
                                    No, seriously.  The latest technology has enabled gamers from around the world to play and experience the Metaverse together.
                                    Ready Up Arena is the premier platform to allow them to connect, play, wager, and track their progress. We also support all the most popular gaming platforms for modern gamers to ensure you can always find your next teammate or match.
                                </p>
                            </div>
                            
                            <!-- Grid -->
                            <div class="row services-grid">
                                <!-- Item -->
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                    <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                        <div class="services-icon">
                                        <img src="img/oculus-logo.png">
                                        <img src="img/steam-logo.png">
                                        <img src="img/vive-logo.png">
                                        <img src="img/index-logo.png">
                                        </div>
                                        <h3 class="services-title green">Virtual Reality</h3>
                                        <div class="services-descr" style="text-align:left;">
                                            Virtual Reality is changing the face of gaming to add a whole new dimension of immersion and connection between players in the Metaverse.
                                        </div>
                                    </div>
                                </div>
                                <!-- End Item -->

                                <!-- Item -->
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                    <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                        <div class="services-icon">
                                        <img src="img/windows-logo.png">
                                        <img src="img/playstation-logo.png">
                                        <img src="img/xbox-logo.png">
                                        <img src="img/switch-logo.png">
                                        </div>
                                        <h3 class="services-title green">Console and PC</h3>
                                        <div class="services-descr" style="text-align:left;">
                                            We also support all major PC and console platforms that appeal to modern gamers to ensure that no matter what platform, you can find your next team or opponent.
                                        </div>
                                    </div>
                                </div>
                                <!-- End Item -->

                                <!-- Item -->
                                <div class="col-sm-6 col-md-4 col-lg-4">
                                    <div class="services-item text-center wow fadeIn" data-wow-delay="0" data-wow-duration="1.5s">
                                        <div class="services-icon">
                                        <img src="img/android-logo.png">
                                        <img src="img/apple-logo.png">
                                        </div>
                                        <h3 class="services-title green">Mobile</h3>
                                        <div class="services-descr" style="text-align:left;">
                                            Online gaming has expanded into mobile platforms and RUA has support for the hottest online mobile titles for competitive and cooperative play.
                                        </div>
                                    </div>
                                </div>
                                <!-- End Item -->
                            </div>
                            <!-- End Services Grid -->
                        </div>
                    </div>
                </section>
                <!-- End Home Section -->

                <!-- Home Section -->
                <section class="page-section bg-dark  pt-40 pb-20">
                    <div class="container relative d-flex align-items-center">
                        <div class="col-sm-8 offset-sm-2 wow fadeInUpShort">
                                <h2 class="section-title">Current Game Titles</h2>
                                <p class="section-title-descr" style="text-align:left;">
                                    We support the most popular titles, but we are always adding more.  With our GameScope&trade; technology, we can add new games and platforms quickly and allow our users to vote on the next titles to be supported.
                                </p>
                                <!-- Nav Tabs -->
                                <div class="align-center mb-40 mb-xxs-30">
                                    <ul class="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
                                        
                                        <li class="nav-item">
                                            <a href="#vr" aria-controls="vr" class="nav-link active green" data-bs-toggle="tab" role="tab" aria-selected="true">Virtual Reality</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#console" aria-controls="console" class="nav-link green" data-bs-toggle="tab" role="tab" aria-selected="false">PC & Console</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#mobile" aria-controls="mobile" class="nav-link green" data-bs-toggle="tab" role="tab" aria-selected="false">Mobile</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <!-- End Nav Tabs -->
                                
                                <!-- Tab panes -->
                                <div class="tab-content tpl-minimal-tabs-cont section-text align-center">
                                    <div class="tab-pane fade show active" id="vr" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix hover-white hide-titles" id="work-grid">
                                                                                        
                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-23.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>


                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-24.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-25.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-26.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-27.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-28.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-34.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-35.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-42.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-43.png" alt="Work Description" class="wow-p fadeIn"/>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                    
                                    <div class="tab-pane fade" id="console" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix hover-white hide-titles" id="work-grid">
                                            
                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-1.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-2.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-3.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>                                           

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-4.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-5.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-9.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-10.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-11.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-15.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-16.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-17.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-20.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-21.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                        
                                    <div class="tab-pane fade" id="mobile" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix" id="work-grid">
                                            
                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-21.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-21.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-21.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <a class="work-ext-link">
                                                    <div>
                                                        <div class="work-img-bg wow-p scalexIn"></div>
                                                        <img src="img/titles/gametitle-21.png" alt="Work Description" class="wow-p fadeIn" />
                                                    </div>
                                                </a>
                                            </li>
                                            
                                        </ul>
                                    </div>
                                    
                                </div>
                                <!-- End Tab panes -->

                        </div>
                    </div>
                </section>
                <!-- End Home Section -->
    </div>
</template>