<template>
    <div>
            
                 <!-- Home Section -->
                <section class="page-section bg-dark light-content" id="home">
                    <div class="container relative text-center">
                        
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1">
                                <h1 class="hs-line-4 mb-30 mb-xs-20 wow fadeInUpShort" data-wow-delay=".1s"><span class="d-inline-block bg-gray-dark round px-3 pb-1">Pricing</span></h1>
                                <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s">Choose the Best Plan for Your&nbsp;Business</h2>
                            </div>
                        </div>
                    
                    </div>
                </section>
                <!-- End Home Section -->
                
                
                <!-- Section -->
                <section class="page-section bg-dark light-content pt-0">
                    <div class="container">
                        
                        <!-- Nav Tabs -->
                        <div class="align-center mb-70 mb-xxs-50 wow fadeInUpShort">
                            <ul class="nav nav-tabs tpl-minimal-tabs animate" id="myTab-3" role="tablist">
                                
                                <li class="nav-item">
                                    <a href="#monthly" class="nav-link active" id="monthly-tab" data-bs-toggle="tab"  role="tab" aria-controls="monthly" aria-selected="true">Monthly</a>
                                </li>
                                
                                <li class="nav-item">
                                    <a href="#yearly" class="nav-link" id="yearly-tab" data-bs-toggle="tab" role="tab" aria-controls="yearly" aria-selected="false">Yearly</a>
                                </li>
                                
                            </ul>
                        </div>
                        <!-- End Nav Tabs -->
						
                        <!-- Tab panes -->
                        <div class="tab-content tpl-minimal-tabs-cont section-text align-center wow fadeInUpShort" id="myTabContent-1">
                            
                            <div class="tab-pane fade show active" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                                
                                <!-- Pricing row -->
                                <div class="row">
                                	
                                    <!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-paper-plane"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Start
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="sf-list pr-list">
        													<li>1 Gb Storage</li>
        													<li>3 Domain Names</li>
        													<li>5 FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>19
        											</div>
        											
        											<div class="pr-per">
        												per month
        											</div>											
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
        							<!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item main">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-gift"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Standard
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="sf-list pr-list">
        													<li>3 Gb Storage</li>
        													<li>5 Domain Names</li>
        													<li>7 FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>25
        											</div>
        											
        											<div class="pr-per">
        												per month
        											</div>
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
        							<!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-suitcase"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Enterprise
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="pr-list">
        													<li>15 Gb Storage</li>
        													<li>Unlimit Domain Names</li>
        													<li>Unlimit FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>39
        											</div>
        											
        											<div class="pr-per">
        												per month
        											</div>
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
                                </div>
        						<!-- End Pricing row -->
                                
                            </div>
                            
                            <div class="tab-pane fade" id="yearly" role="tabpanel" aria-labelledby="yearly-tab">
                            
                                <!-- Pricing row -->
                                <div class="row">
                                	
                                    <!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-paper-plane"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Start
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="sf-list pr-list">
        													<li>1 Gb Storage</li>
        													<li>3 Domain Names</li>
        													<li>5 FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>199
        											</div>
        											
        											<div class="pr-per">
        												per year
        											</div>											
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
        							<!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item main">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-gift"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Standard
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="sf-list pr-list">
        													<li>3 Gb Storage</li>
        													<li>5 Domain Names</li>
        													<li>7 FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>239
        											</div>
        											
        											<div class="pr-per">
        												per year
        											</div>
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
        							<!-- Pricing Item -->
                                    <div class="col-md-4">
                                        <div class="pricing-item">
                                            <div class="pricing-item-inner round">
                                                <div class="pricing-wrap">
                                                	
        											<!-- Icon -->
                                                    <div class="pricing-icon">
                                                        <i class="fa fa-suitcase"></i>
                                                    </div>
        											
        											<!-- Pricing Title -->
                                                    <div class="pricing-title">
                                                        Enterprise
                                                    </div>
        											
        											<!-- Pricing Features -->
                                                    <div class="pricing-features">
                                                        <ul class="pr-list">
        													<li>15 Gb Storage</li>
        													<li>Unlimit Domain Names</li>
        													<li>Unlimit FTP Users</li>
        													<li>Free Support</li>
        												</ul>
                                                    </div>
        											
        											<div class="pricing-num">
                                                        <sup>$</sup>399
        											</div>
        											
        											<div class="pr-per">
        												per year
        											</div>
        											
        											<!-- Button -->											
                                                    <div class="pr-button">
                                                        <a href="" class="btn btn-mod btn-w btn-small round">Buy Now</a>
                                                    </div>
        											
        										</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Pricing Item -->
        							
                                </div>
        						<!-- End Pricing row -->
                            
                            </div>
                            
                        </div>
                                
                    </div>
                </section>
                <!-- End Section -->
                
                
                <!-- Divider -->
                <hr class="mt-0 mb-0 white" />
                <!-- End Divider -->
                
                
                <!-- Call Action Section -->
                <section class="small-section bg-dark light-content">
                    <div class="container relative">
                        <div class="row wow fadeInUpShort">
                            <div class="col-md-8 offset-md-2 text-center">
                                <h3 class="call-action-1-heading">Want to discuss your new project?</h3>
                                <div class="call-action-1-decription mb-60 mb-sm-30">
                                    Proin fringilla augue at maximus vestibulum. Nam pulvinar vitae neque et porttitor. Integer non dapibus diam, ac eleifend lectus. Duis placerat ex gravida nibh tristique ultricies eros lorem blandit.
                                </div>                                        
                            
                                <div class="local-scroll">
                                    <a href="pages-contact-1-dark.html" class="btn btn-mod btn-w btn-large btn-round">Let's Talk</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End Call Action Section -->
    </div>
</template>


<script>
export default {
  name: 'pricing',
  props: {
    
  }
}
</script>