<template>
    <div>
           <!-- Home Section -->
                <section class="small-section bg-dark-alfa-50 bg-scroll light-content" data-background="images/full-width-images/section-bg-19.jpg" id="home">
                    <div class="container relative pt-70">
                    
                        <div class="row">
                            
                            <div class="col-md-8">
                                <div class="wow fadeInUpShort" data-wow-delay=".1s">
                                    <h1 class="hs-line-7 mb-20 mb-xs-10">About Us</h1>
                                </div>
                                <div class="wow fadeInUpShort" data-wow-delay=".2s">
                                    <p class="hs-line-6 opacity-075 mb-20 mb-xs-0">
                                        Extraordinary art team & creative minimalism lovers
                                    </p>
                                </div>
                            </div>
                            
                            <div class="col-md-4 mt-30 wow fadeInUpShort" data-wow-delay=".1s">
                                <div class="mod-breadcrumbs text-end">
                                    <a href="#">Home</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<a href="#">Pages</a>&nbsp;<span class="mod-breadcrumbs-slash">•</span>&nbsp;<span>About Us</span>
                                </div>                                
                            </div>
                            
                        </div>
                    
                    </div>
                </section>
                <!-- End Home Section -->

                <!-- About Section -->
                <section class="page-section bg-dark light-content" id="about">
                    <div class="container relative">
                        
                        <div class="section-text mb-60 mb-sm-40">
                            <div class="row">
                                
                                <div class="col-md-6 mb-md-50 mb-xs-30 wow fadeInUpShort" data-wow-offset="0">                                                                        
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam pulvinar vitae neque et porttitor. Integer non dapibus diam, ac eleifend lectus. In maximus ligula semper metus pellentesque mattis. Maecenas  volutpat, diam enim sagittis quam. 
                                    </p>
                                    <p>
                                        Etiam sit amet fringilla lacus. Pellentesque suscipit ante at ullamcorper pulvinar neque porttitor. Integer lectus. Praesent sed nisi eleifend, fermentum orci amet, iaculis libero.
                                    </p>                                    
                                </div>
                                
                                <div class="col-md-6 mb-md-50 mb-xs-30 wow fadeInUpShort" data-wow-offset="0">
                                     
                                    <!-- Bar Item -->
                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">
                                            <div>Branding, %</div> <span>90</span>
                                        </div>
                                    </div>
                                    <!-- End Bar Item -->
                                    
                                    <!-- Bar Item -->
                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">
                                            <div>Design, %</div> <span>80</span>
                                        </div>
                                    </div>
                                    <!-- End Bar Item -->
                                    
                                    <!-- Bar Item -->
                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100">
                                            <div>Development, %</div> <span>85</span>
                                        </div>
                                    </div>
                                    <!-- End Bar Item -->
                                    
                                    <!-- Bar Item -->
                                    <div class="progress tpl-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                            <div>Photo, %</div> <span>75</span>
                                        </div>
                                    </div>
                                    <!-- End Bar Item -->
                                     
                                </div>
                                
                            </div>
                        </div>
                        
                         <!-- Counters -->
                        <div class="count-wrapper">
                            <div class="row">
                                
                                <!-- Counter Item -->
                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1s">
                                            <div class="count-number">
                                                320
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-briefcase"></i>
                                                <span class="count-title">Projects Done</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Counter Item -->
                                
                                <!-- Counter Item -->
                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.1s">
                                            <div class="count-number">
                                                150
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-heart"></i>
                                                <span class="count-title">Happy Clients</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Counter Item -->
                                
                                <!-- Counter Item -->
                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.2s">
                                            <div class="count-number">
                                                933
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-coffee"></i>
                                                <span class="count-title">Coffee Cups</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Counter Item -->
                                
                                <!-- Counter Item -->
                                <div class="col-md-6 col-lg-3 mb-md-30">
                                    <div class="count-item">
                                        <div class="count-bg wow scalexIn"></div>
                                        <div class="relative wow fadeIn" data-wow-delay="1.3s">
                                            <div class="count-number">
                                                975
                                            </div>
                                            <div class="count-descr">
                                                <i class="fa fa-lightbulb"></i>
                                                <span class="count-title">Great Ideas</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- End Counter Item -->
                                
                            </div>
                        </div>
                        <!-- End Counters -->
                        
                    </div>
                </section>
                <!-- End About Section -->         
                
                
                <!-- Testimonials Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 bg-scroll light-content" data-background="images/full-width-images/section-bg-2.jpg">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">What People Say?</h2>
                            </div>
                            
                            <div class="text-slider">
                                
                                <!-- Slide Item -->
                                <div>
                                    <blockquote class="testimonial">
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                        <footer class="testimonial-author mt-50 mt-sm-20">
                                            — John Doe, doodle inc.
                                        </footer>
                                    </blockquote>
                                </div>
                                <!-- End Slide Item -->
                                
                                <!-- Slide Item -->
                                <div>
                                    <blockquote class="testimonial">
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                        <footer class="testimonial-author mt-50 mt-sm-20">
                                            — John Doe, doodle inc.
                                        </footer>
                                    </blockquote>
                                </div>
                                <!-- End Slide Item -->
                                
                                <!-- Slide Item -->
                                <div>
                                    <blockquote class="testimonial">
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                        <footer class="testimonial-author mt-50 mt-sm-20">
                                            — John Doe, doodle inc.
                                        </footer>
                                    </blockquote>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            
                                                      
                        </div>
                    </div>
                        
                    </div>
                </section>
                <!-- End Testimonials Section -->
                
                
                <!-- Logotypes Section -->
                <section class="small-section pt-20 pb-20 bg-dark-lighter light-content">
                    <div class="container relative">
                        
                        <div class="row wow fadeInUpShort">
                            <div class="col-md-12">
                                
                                <div class="small-item-carousel black owl-carousel mb-0">
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-1.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-2.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-3.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-4.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-5.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-6.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-1.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                    <!-- Logo Item -->
                                    <div class="logo-item">
                                        <img src="images/clients-logos/dark/client-2.png" width="150" height="90" alt="Company Name" />
                                    </div>
                                    <!-- End Logo Item -->
                                    
                                </div>
                                    
                             </div>
                         </div>
                        
                     </div>
                </section>
                <!-- End Logotypes -->
                
                
                <!-- Divider -->
                <hr class="mt-0 mb-0 white" />
                <!-- End Divider -->
                
                
                <!-- Story Section -->
                <section class="page-section bg-dark-lighter light-content">
                    <div class="container relative">
                        
                        <div class="text-center mb-80 mb-sm-50">
                            <h2 class="section-title">Our Story</h2>
                            <p class="section-title-descr">
                                Learn more about our company.
                            </p>
                        </div>
                        
                        <!-- Our Story -->
                        <div class="row">
                                                   
                            <div class="col-sm-8 offset-sm-2 wow fadeInUpShort">
                                
                                <!-- Nav Tabs -->
                                <div class="align-center mb-40 mb-xxs-30">
                                    <ul class="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
                                        
                                        <li class="nav-item">
                                            <a href="#mission" aria-controls="mission" class="nav-link active" data-bs-toggle="tab" role="tab" aria-selected="true">Mission</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#vision" aria-controls="vision" class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">Vision</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#ideas" aria-controls="ideas" class="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">Ideas</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <!-- End Nav Tabs -->
                                
                                <!-- Tab panes -->
                                <div class="tab-content tpl-minimal-tabs-cont section-text align-center">
                                    
                                    <div class="tab-pane fade show active" id="mission" role="tabpanel">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis lacus augue, pharetra non congue sit amet, bibendum sit amet enim. a hendrerit leo tristique vitae. Mauris non ipsum molestie, sagittis elit ac, vulputate odio. Fusce quam augue, gravida tincidunt dui nec, tempor iaculis justo. 
                                        Aliquam tortor leo, pharetra non congue sit amet, bibendum sit amet enim.
                                    </div>
                                    
                                    <div class="tab-pane fade" id="vision" role="tabpanel">
                                        Nam porta elementum tortor, eget tempor orci ullamcorper eget. Aliquam fermentum sem non vulputate dapibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla at porttitor massa.
                                        Aliquam tortor leo, pharetra non congue sit amet pharetra non congue sit amet, bibendum sit amet enim.
                                    </div>
                                        
                                    <div class="tab-pane fade" id="ideas" role="tabpanel">
                                        Pellentesque sed vehicula velit, vitae vulputate velit. Morbi nec porta augue, et dignissim enim. Vivamus suscipit, lorem vitae rhoncus pharetra, erat nisl scelerisque magna, ut mollis dui eros eget libero. Vivamus ut ornare tellus.
                                        Aliquam tortor leo, pharetra pharetra non congue sit amet non congue sit amet, bibendum sit amet enim.
                                    </div>
                                    
                                </div>
                                <!-- End Tab panes -->

                            </div>
                        </div>
                        <!-- End Our Story -->
                    </div>
                </section>
                <!-- End Story Section -->
                
                
                 <!-- Contact Form -->                            
                        <div class="row">
                            <div class="col-md-10 offset-md-1">
                                
                                <form class="form contact-form wow fadeInUpShort" id="contact_form">
                                    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <!-- Name -->
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <input type="text" name="name" id="name" class="input-lg round form-control" placeholder="Enter your name" pattern=".{3,100}" required aria-required="true">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <!-- Email -->
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" name="email" id="email" class="input-lg round form-control" placeholder="Enter your email" pattern=".{5,100}" required aria-required="true">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Message -->
                                    <div class="form-group">
                                        <label for="message">Message</label>
                                        <textarea name="message" id="message" class="input-lg round form-control" style="height: 130px;" placeholder="Enter your message"></textarea>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <!-- Inform Tip -->
                                            <div class="form-tip pt-20 pt-sm-0 mb-sm-20">
                                                All the fields are required
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <!-- Send Button -->
                                            <div class="text-end pt-10">
                                                <button class="submit_btn btn btn-mod btn-w btn-large btn-round" id="submit_btn" aria-controls="result">
                                                    Submit Message
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                   
                                   <div id="result" role="region" aria-live="polite" aria-atomic="true"></div>
                                   
                                </form>
                                
                            </div>
                        </div>
                        <!-- End Contact Form -->


    </div>
</template>

<script>
export default {
  name: 'about',
  props: {
    
  }
}
</script>
