<template>
    <div>
         <!-- Tournament League Section -->
                <section id="features" class="page-section" style="background-image: url('img/competition-bw-bg.jpg');">
                    <div class="container relative">
                        
                        <!-- Grid -->
                        <div class="row">
                            
                            <!-- Text Item -->
                            <div class="col-md-12 col-lg-3">
                                <div class="align-left">
                                    <h2 class="work-process-heading mt-0">Create Your Own Online Matches, Tournaments, and Leagues</h2>
                                    <p class="work-process-description">
                                        Lorem ipsum dolor sit amet casume adipisin elit. In maximus ligula semper metus pellentesque mattis. Maecenas volutpat, diam enim.
                                    </p>
                                </div>
                            </div>
                            <!-- End Text Item -->
                       </div>
                       <!-- End Grid -->
                        
                    </div>
                </section>
                <!-- Tournament League Section -->

                 <!-- Stream Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 light-content">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Get Pro Event Level Online Promotion and Production</h2>
                            </div>
                            

                                
                                <!-- Slide Item -->
                                <div>
                                        <p>
                                            Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            

                    </div>
                        
                    </div>
                </section>
                <!-- End Streaming Section -->


                <!-- Rewards Section -->
                <section class="page-section bg-dark bg-dark-alfa-70 bg-scroll light-content" style="background-image: url('img/nft-bg.jpg');">
                    <div class="container relative">
                        
                    <div class="row">
                        <div class=" col-lg-8 offset-lg-2 wow fadeInUpShort">
                            
                            <div class="text-center mb-50 mb-sm-20">
                                <h2 class="section-title">Wager and Win Rewards</h2>
                            </div>
                            

                                
                                <!-- Slide Item -->
                                <div>
                                        <p>
                                            Even if you aren't a gamer, you can still participate and win!  Phasellus luctus commodo ullamcorper a posuere rhoncus commodo elit aenean congue, risus utaliquam dapibus lorem ipsum dolor. Thanks!
                                        </p>
                                </div>
                                <!-- End Slide Item -->
                                
                            </div>                            

                    </div>
                        
                    </div>
                </section>
                <!-- End Rewards Section -->

                <!-- Participate Section -->
                <section id="about" class="page-section  bg-pattern">
                    <div class="container relative">
                        
                        <!-- Grid -->
                        <div class="row">
                            
                            <!-- Text Item -->
                            <div class="col-md-12 col-lg-3">
                                <div class="align-left">
                                    <h2 class="work-process-heading mt-0">About Us / RUA</h2>
                                    <p class="work-process-description">
                                        Lorem ipsum dolor sit amet casume adipisin elit. In maximus ligula semper metus pellentesque mattis. Maecenas volutpat, diam enim.
                                    </p>
                                </div>
                            </div>
                            <!-- End Text Item -->
                       </div>
                       <!-- End Grid -->
                        
                    </div>
                </section>
                <!-- Participate Section -->
    </div>
</template>