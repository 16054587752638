<template>
    <div>
        <!-- Home Section -->
        <section class="home-section bg-dark bg-dark-alfa-40 parallax-5" id="home" style="background-image: url('img/competition-bg-bw.jpg'); width:100%;">
            <div class="container min-height-100vh d-flex align-items-center pt-100 pb-100" style="padding:0px; max-width: 800px; margin:auto;">
                <div class="row">
                    <div class="col-lg-12">
                        <img src='img/rua-logo.png' class="hero-image drop-shadow" style="width: 100%;">
                        <h2 class="hs-line-4 mb-60 mb-xs-40 wow fadeInUpShort" data-wow-delay=".2s"><span class="d-inline-block round px-3 pb-1">Where Gamers and Fans Come to Meet and Compete &trade;</span></h2>
                        <div class="local-scroll mb-20 wow fadeInUpShort" data-wow-delay=".3s">
                            <a href="#abt" class="btn btn-mod btn-medium btn-round mx-md-1" style="background-color: #c1c1c1; color: black; margin:8px;">Learn More</a>
                            <a href="#sign" class="btn btn-mod btn-w btn-medium btn-round mx-md-1" style="margin:8px;">Sign-Up Free</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Home Section -->

        <!-- Divider -->
        <hr class="mt-0 mb-0" id="abt"/>
        <!-- End Divider -->

        <!-- Career Section -->
        <section class="page-section pt-0 pb-10 banner-section light-content bg-pattern-green" style="color: black !important; margin-bottom:-80px;">
            <div class="container relative">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="banner-image-1 wow fadeIn drop-shadow" data-wow-delay="0" data-wow-duration="1.5s">
                            <img src="img/gamer-3.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="292" />
                        </div>
                        <div class="banner-image-2 wow fadeIn drop-shadow" data-wow-delay=".6" data-wow-duration="1.8s" style="z-index:1000;">
                            <img src="img/gamer-2.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="70" />
                        </div>
                    </div>
                    <div class="col-lg-6">                                
                        <div class="mt-80 mb-80 pt-80 pb-80">
                            <div class="banner-content wow fadeInUpShort" data-wow-duration="1.2s">
                                <h3 class="banner-heading">Looking to start an online gaming career?</h3>
                                <div class="banner-decription" style="text-align:left;">
                                    Whether you are a gamer, streamer, referee, or shoutcaster, RUA can help you meet other aspiring individuals and 
                                    gain exposure while earning rewards as you grow.
                                </div>
                            </div>
                        </div>                             
                    </div>
                </div>
            </div>
        </section>
        <!-- End Career Section -->

        <!-- Divider -->
        <hr class="mt-0 mb-0" />
        <!-- End Divider -->

          <!-- Section -->
                <section class="page-section page-section-2 bg-dark bg-dark-alfa-70 parallax-5" style="background-color: black !important; background-image: url('img/gamer-1-bw.jpg');" >
                    <div class="container relative">
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2">
                                <div class="text-center mb-20 mb-sm-50">
                                    <h2 class="section-title">Play and Build Your Brand</h2>
                                    <p class="section-title-descr white text-shadow">
                                        Ready Up Arena has built the network and tools to help you meet new players, build your brand, improve your game, or just simply watch and win
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Grid -->
                        <div class="alt-service-grid">
                            <div class="row pt-40 pt-md-0">
                                
                                <div class="col-sm-6 col-md-6 col-lg-3 wow fadeInUpShort">
                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-handshake"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Connect</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Meet new teammates or competitors at your level for competitive or co-operative play.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>

                                    </div>
                                    <!-- End Item -->

                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-eye"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Scout</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Whether it's a teammate needed, or a shoutcaster for your next virtual event broadcast, find the talent you are looking for.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>                                        
                                    </div>
                                    <!-- End Item -->


                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-sitemap"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Organize</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Create your own gaming organization and teams. Manage matches, tournaments, and leagues.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Item -->

                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-gamepad"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Host</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Host your own virtual gaming event with professional level streaming, production, and promotion tools.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Item -->

                                </div>
                                
                                <div class="col-lg-6 pt-60 d-none d-lg-block">
                                    <div class="alt-services-image px-lg-4">
                                        <div class="overflow-hidden">
                                            <img src="img/gamers.jpg" alt="Add Your Description" class="wow scaleOutIn" style="max-height:780px;" />
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6 col-lg-3 wow fadeInUpShort">
                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-coins"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Earn</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Earn rewards by being a member and playing in matches, tournaments, and leagues.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Item -->  

                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">                                
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-seedling"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Grow</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Develop your brand and personal style while networking, making new friends, and leveling up your game.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <!-- End Item -->


                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-bullhorn"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Promote</h3>
                                            <div class="alt-services-descr white text-shadow" style="text-align:left;">
                                                Easily promote your brand, organization, team, matches, tournaments, and leagues at a professional level with RUA's state of the art tools.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Item -->
                      
                                    <!-- Alt Item -->
                                    <div class="alt-service-wrap">
                                        <div class="alt-service-item green">
                                            <div class="alt-service-icon green">
                                                <i class="fa fa-heart"></i>
                                            </div>
                                            <h3 class="alt-services-title" style="text-align:left;">Engage</h3>
                                            <div class="alt-services-descr white text-shadow"  style="text-align:left;">
                                                Interact with your team, your competition, and your audience while you play to grow your community.
                                                <!--<br><router-link to="/">Learn More ></router-link>-->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End Item -->   
                                </div>
                                
                            </div>
                        </div>
                        <!-- End Grid -->
                        
                    </div>
                </section>
                <!-- End Section -->     

                
        <!-- Career Section -->
        <section id="career" class="page-section pt-40 pb-0 banner-section dark-content bg-pattern-green" style="color:black;">
            <div class="container relative">
                <div class="row">

                    <div class="col-lg-5">                                
                        <div class="mt-100 mb-100">
                            <div class="banner-content wow fadeInUpShort pb-30" data-wow-duration="1.2s">
                                <h3 class="banner-heading">Wager and Win</h3>
                                <div class="banner-decription" style="text-align:left;">
                                    As you play, you can wager and win rewards.  Even if you aren't a gamer, you can still earn, wager, and win rewards just for watching and participating with other players in matches.
                                    <!--<br><router-link to="/" style="color: black; float:left; margin-top:6px;">Learn More ></router-link>-->
                                </div>
                            </div>
                        </div>                             
                    </div>

                    <div class="col-lg-6 offset-lg-1 relative">
                        <div class="banner-image-3 wow fadeIn drop-shadow" data-wow-delay="0" data-wow-duration="1.5s">
                            <img src="img/gamers-win.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="292" />
                        </div>
                        <div class="banner-image-4 wow fadeIn drop-shadow" data-wow-delay=".6" data-wow-duration="1.8s" style="z-index:1000;">
                            <img src="img/gamers-win-2.jpg" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="70" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Career Section -->       
                
        <!-- Logotypes Section -->
        <section class="page-section page-section-2 bg-dark bg-dark-alfa-50 parallax-5" style="background-image: url('img/vr-1-bw.jpg');" >
            <div class="container relative mt-20 mb-20">
                <h2>Take it to the Metaverse</h2>
                <p class="section-title-descr white text-shadow"  style="text-align:left;">
                    No, seriously.  The latest technology has enabled users from around the world to engage and experience the Metaverse together.
                    Ready Up Arena is the premier platform to allow you to connect, play, wager, and track your progress. Even if you aren't looking to game and just want to plan a movie night or just meet new friends in the Metaverse, we've got you covered.
                    <br><br>
                    We support the most popular gaming and streaming platforms to ensure you can always find your next teammate, match, or social hangout.
                </p>
                
                <div class="row">
                    <span class="gray drop-shadow" style="margin: 20px 0 -10px 0">Connect</span>
                    <center>
                    <div class="col-md-12" style="text-align:center !important;">
                        <img src="img/oculus-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/steam-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/windows-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/playstation-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/xbox-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/switch-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/android-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/apple-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                    </div>
                    </center>
                </div>

                <div class="row">
                    <span class="gray drop-shadow" style="margin: 60px 0 -10px 0">Live Stream</span>
                    <div class="col-md-12" style="text-align:center !important;">
                        <img src="img/twitch-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/youtube-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                        <img src="img/facebook-logo.png" width="80" class="drop-shadow" style="margin:20px;"/>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Logotypes -->

         <!-- Logotypes Section -->
        <section class="page-section bg-dark pt-80 pb-80 bg-pattern">
            <div class="container relative">
                <div class="row">
                    <h2>Current Titles</h2>
                    <!-- Nav Tabs -->
                                <div class="align-center mb-40 mb-xxs-30">
                                    <ul class="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
                                        <li class="nav-item">
                                            <a href="#vr" aria-controls="vr" class="nav-link active green" data-bs-toggle="tab" role="tab" aria-selected="true">Virtual Reality</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#console" aria-controls="console" class="nav-link green" data-bs-toggle="tab" role="tab" aria-selected="false">PC & Console</a>
                                        </li>
                                        
                                        <li class="nav-item">
                                            <a href="#mobile" aria-controls="mobile" class="nav-link green" data-bs-toggle="tab" role="tab" aria-selected="false">Mobile</a>
                                        </li>
                                        
                                    </ul>
                                </div>
                                <!-- End Nav Tabs -->
                                
                                <!-- Tab panes -->
                                <div class="tab-content tpl-minimal-tabs-cont section-text align-center">
                                    <div class="tab-pane show active" id="vr" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix hide-titles" id="work-grid">
                                                                                        
                                            <li class="work-item mix branding design">
                                               <img src="img/titles/gametitle-23.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-24.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-25.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                               <img src="img/titles/gametitle-26.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-27.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-28.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-34.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-35.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-42.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                               <img src="img/titles/gametitle-43.jpg" class="wow-p fadeIn box-shadow"/>
                                            </li>

                                        </ul>
                                    </div>
                                    
                                    <div class="tab-pane show" id="console" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix" id="work-grid">
                                            
                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-1.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-2.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-3.jpg" class="box-shadow"/>
                                            </li>                                           

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-4.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-5.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-9.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-10.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-11.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-15.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-16.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-17.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-20.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-21.jpg" class="box-shadow"/>
                                            </li>

                                        </ul>
                                    </div>
                                        
                                    <div class="tab-pane" id="mobile" role="tabpanel">
                                        <ul class="works-grid work-grid-3 work-grid-gut clearfix hide-titles" id="work-grid">
                                            
                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-44.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-45.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-46.jpg" class="box-shadow"/>
                                            </li>

                                            <li class="work-item mix branding design">
                                                <img src="img/titles/gametitle-47.jpg" class="box-shadow"/>
                                            </li>

                                        </ul>
                                    </div>
                                    
                                </div>
                                <!-- End Tab panes -->
                </div>
            </div>
        </section>
        <!-- End Logotypes -->

        <!-- About Section -->
        <section id="career" class="page-section pt-20 pb-30 banner-section light-content" style="background-color:black;">
            <div class="container relative">
                <div class="row">
                    <div class="col-lg-4 mt-40 mb-20">
                        <img src="img/rua-icon.png" alt="" class="wow scaleOutIn" data-wow-duration="1.2s" data-wow-offset="292" style="width: 100%; max-width:350px;" />
                    </div>
                    <div class="col-lg-8">                                
                        <div class="pt-20 pb-0">
                            <div class="banner-content wow fadeInUpShort" data-wow-duration="1.2s">
                                <h3 class="banner-heading">What is RUA?</h3>
                                <div class="banner-decription white text-shadow" style="text-align:left;">
                                    RUA is an app and social gaming platform created by gamers for gamers, and that's not just lip service.  As long time gamers and VR enthusiasts, we found ourselves wanting more social interaction out of online gaming matches.
                                    We wanted a polished online match streaming experience like professional events had, even though we didn't have the budget or skill to go pro.  
                                    We never could seem to find the right people to team up with for that late night tournament or to play co-op when we wanted to.   
                                    Since we knew a lot about software and had a deep passion for social gaming, we had no choice but to create a solution for us and every other gamer that wished they could just find more.
                                </div>
                            </div>
                        </div>                             
                    </div>
                </div>
            </div>
        </section>
        <!-- End About Section -->

                
       <!-- Signup Section --> 
        <section id="sign" class="page-section page-section-2 bg-dark-alfa-50 parallax-5 pt-80 pb-80" style="background-image: url('img/gamer-bg-1-bw.jpg');">
            <div class="container relative pt-80 pb-80">
                
                <form id="mailchimp" class="form wow fadeInUpShort">
                    <div class="row">
                        <div class="col-md-8 offset-md-2">
                            
                            <div class="mb-50 mb-sm-20">
                                <h2>
                                    Sign Up For the Public Beta 
                                </h2>
                                <p class="white text-shadow" style="text-align:left;">
                                    We're still busy building and closed testing, but we would love to invite you to help us test when it's ready.  Sign up now for free to secure your place in line to stay up to date and get an invite when the beta opens.
                                </p>
                            </div>
                 
                                <form action="https://rua.us5.list-manage.com/subscribe/post?u=8f057085717530786e8f49079&amp;id=24737ead7d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                                    <input type="email" value="" name="EMAIL" class="newsletter-field input-lg round" id="mce-EMAIL" placeholder="email address" required pattern=".{5,100}" aria-required="true">
                                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_8f057085717530786e8f49079_24737ead7d" tabindex="-1" value=""></div>
                                    <button type="submit" aria-controls="subscribe-result" class="newsletter-button btn btn-mod btn-w btn-large btn-round">Sign-Up</button>
                                </form>
                        
                            
                            <div class="form-tip">
                                
                            </div>
                            
                            <div id="subscribe-result" role="region" aria-live="polite" aria-atomic="true"></div>
                            
                        </div>
                    </div>
                </form>
                
            </div>
        </section>
        <!-- End Signup Section -->

    </div>    
</template>

<script>
export default {
  name: 'landing',
  props: {
    
  },
methods:{
      moveUp(){
          window.scrollTo(0,0);
      }
  }
}
</script>