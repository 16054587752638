<template>
    <div>
            
                <!-- Home Section -->
                <section class="page-section bg-dark light-content" id="home">
                    <div class="container relative text-center">
                        
                        <div class="row">
                            <div class="col-lg-10 offset-lg-1">
                                <h1 class="hs-line-4 mb-30 mb-xs-20 wow fadeInUpShort" data-wow-delay=".1s"><span class="d-inline-block bg-gray-dark round px-3 pb-1">FAQ</span></h1>
                                <h2 class="hs-line-7 mb-0 wow fadeInUpShort" data-wow-delay=".2s">Frequently Asked Questions</h2>
                            </div>
                        </div>
                    
                    </div>
                </section>
                <!-- End Home Section -->
                
                
                <!-- Section -->
                <section class="page-section bg-dark light-content pt-0">
                    <div class="container relative">
                        
                        <div class="row section-text wow fadeInUpShort">
                            <div class="col-md-8 offset-md-2">
                                
                                <!-- Accordion -->
                                <dl class="accordion">
                                    
                                    <dt>
                                        <a href="">What do you mean by an End Product?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">Where do I find my Purchase or License code?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">Do I need to buy a licence for each site?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">Is my license transferable?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">Can I incorporate advertising in my end product with a regular licence?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">If I make a clip for YouTube using music from AudioJungle, can I monetize the clip by allowing ads?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                    <dt>
                                        <a href="">Do I need a separate license each time I use an item in a series?</a>
                                    </dt>
                                    <dd>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula porttitor rutrum. Ut laoreet sodales rutrum. Ut eget tristique nibh. Cras mattis est eget lacus dignissim, non consectetur velit pellentesque. Ut sit amet placerat eros, id gravida arcu. Sed felis purus, tempor ac vestibulum nec, consectetur at purus. 
                                    </dd>
                                    
                                </dl>
                                <!-- End Accordion -->
                                
                            </div>
                        </div>
                        
                    </div>
                </section>
                <!-- End Section -->
                
                
                <!-- Divider -->
                <hr class="mt-0 mb-0 white" />
                <!-- End Divider -->
                
                
                <!-- Call Action Section -->
                <section class="small-section bg-dark light-content">
                    <div class="container relative">
                        <div class="row wow fadeInUpShort">
                            <div class="col-md-8 offset-md-2 text-center">
                                <h3 class="call-action-1-heading mb-40 mb-sm-20">Have not find the right answer?</h3>
                                <div class="local-scroll">
                                    <a href="pages-contact-1-dark.html" class="btn btn-mod btn-w btn-large btn-round">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- End Call Action Section -->

    </div>
</template>


<script>
export default {
  name: 'faq',
  props: {
    
  }
}
</script>